import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import PageBody from "@/components/PageBody";
import Paragraphs from "@/components/Paragraphs";
import Pricing from "../components/language/pricing";
import Centered2x2Grid from "../components/Centered2x2grid";
import CtaWithScreenshot from "../components/language/cta/CtaWithScreenshot";
import Reviews from "../components/language/Reviews";
import Bios from "../components/Bio";
import { languageLandingPages as leadFormFields } from "../config/ContactForm.data";
import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";

const defaultFeatures = [
  {
    name: "Learn from native speakers",
    description:
      "You will be working with a language partner who is a native speaker of the language you want to learn.",
    icon: "GlobeAltIcon",
  },
  {
    name: "Get taught by qualified tutors",
    description: "We have a team of qualified tutors who can help you.",
    icon: "BadgeCheckIcon",
  },
  {
    name: "Have regular consistent lessons",
    description:
      "You and your tutor will schedule lessons at times that work for you, wherever you are in the world.",
    icon: "ClockIcon",
  },
  {
    name: "Find joy, stay motivated!",
    description:
      "Stay motivated by studying the language of your choice in a way that is personalized and helps you reach your goals.",
    icon: "AcademicCapIcon",
  },
];

export default function LanguagePage({ data }) {
  const langPageData = data.allContentfulLanguagePage.edges[0].node;
  const language =
    langPageData.slug[0].toUpperCase() + langPageData.slug.substring(1);

  const modifiedLeadFormFields = { ...leadFormFields };
  modifiedLeadFormFields.fields = [
    ...modifiedLeadFormFields.fields.filter(
      (field) => field.name !== "Language"
    ),
    {
      name: "Language",
      required: true,
      initialValue: langPageData.language,
      type: "hidden",
    },
  ];

  const seo = createSeoData({
    title: langPageData.heroTitle,
    description: langPageData.metaDescription,
    image: langPageData.heroImage
      ? getImage(langPageData.heroImage.localFile).images.fallback.src
      : null,
  });

  const hero = {
    type: "image",
    heroTitleLine1: langPageData.heroTitle,
    heroImage: langPageData.heroImage ? langPageData.heroImage.localFile : null,
    heroContent: langPageData.heroContent,
    heroLongContent: langPageData.heroLongContent
      ? renderRichText(langPageData.heroLongContent)
      : null,
    heroTextColor: langPageData.heroTextColor,
  };

  return (
    <Layout seo={seo} hero={hero} leadFormFields={modifiedLeadFormFields}>
      <PageBody>
        {langPageData.grid2x2 ? (
          <Centered2x2Grid
            title={langPageData.grid2x2.title}
            subtitle={langPageData.grid2x2.subtitle}
            features={langPageData.grid2x2.content}
          />
        ) : (
          <Centered2x2Grid
            title="Benefits on Lingua Fonica"
            subtitle="A Better Way to Learn a Language"
            features={defaultFeatures}
          />
        )}
      </PageBody>
      <Reviews quote="I really liked how our professor would incorporate stories, everyday examples, and videos in the class. It helped me retain information." />
      {langPageData.tutors && (
        <Bios heading="Featured Tutors" people={langPageData.tutors} />
      )}
      {langPageData.products && (
        <Pricing
          products={langPageData.products}
          leadFormFields={modifiedLeadFormFields}
        />
      )}
      <PageBody limitMaxWidth>
        <h2 className="text-5xl font-extrabold text-gray-900 sm:text-center pb-4">
          {langPageData.language} at a Glance
        </h2>
        <Paragraphs rawBody={JSON.parse(langPageData.content.raw)} />
      </PageBody>
      <CtaWithScreenshot
        headerLine1="Ready to begin your journey?"
        headerLine2="Schedule your first lesson now"
        ctaText="Select your class"
        imageUrl="/signUp.png"
        imageAlt="Sign up page on app screenshot"
        language={language}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    allContentfulLanguagePage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          slug
          language
          metaDescription
          heroTextColor
          heroTitle
          heroContent
          heroLongContent {
            raw
          }
          cta
          heroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1200, height: 500, formats: [AUTO, WEBP])
              }
            }
          }

          content {
            raw
          }
          grid2x2 {
            subtitle
            title
            content {
              icon
              name
              description
            }
          }
          tutors {
            bio {
              bio
            }
            role
            id
            name
            profileImage {
              description
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, formats: [AUTO, WEBP])
                }
              }
            }
          }
          products {
            displayName
            features
            name
            description
            lessonSalePrice
            lessonPrice
            bulkLessonAmount
            bulkLessonSalePrice
            bulkLessonsPrice
          }
        }
      }
    }
  }
`;
