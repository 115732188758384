import * as React from "react";
import Paragraph from "./Paragraph";

export default function Paragraphs({ rawBody }) {
  const paragraphsArray = rawBody.content.map(
    (object) => object.content[0].value
  );
  return paragraphsArray.map((text, i) => (
    <Paragraph key={text} disableMarginTop={i === 0}>
      {text}
    </Paragraph>
  ));
}
