/* This example requires Tailwind CSS v2.0+ */
import * as React from "react";
import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import PricingRow from "./PricingRow";
import Form from "../../UIL/Forms";
import Modal from "../../Modal";
import { triggerContactFormSubmitted } from "../../../analytics-helpers";

const SUBMIT_CONTACT_FORM = gql`
  mutation ContactForm($body: JSON) {
    contactForm(body: $body) {
      sent
    }
  }
`;

export default function Pricing({ products, leadFormFields }) {
  const [numOfLessons, setActiveButton] = useState(10);
  const [showModal, setShowModal] = React.useState(false);
  const [formFields, setFormFields] = useState(leadFormFields.fields);
  const [submitContactForm] = useMutation(SUBMIT_CONTACT_FORM);

  const selectedButtonClassName =
    "relative w-1/2 text-white bg-indigo-600 hover:bg-indigo-700 rounded-md shadow-sm py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8";
  const unSelectedButtonClassName =
    "ml-0.5 relative w-1/2 bg-gray-300 hover:bg-gray-500 border border-transparent rounded-md py-2 text-sm font-medium text-black whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8";

  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h2 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Ready to begin your journey?
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:text-center">
            Schedule your first class now!
          </p>

          <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8 space-x-4">
            <button
              type="button"
              className={
                numOfLessons === 1
                  ? selectedButtonClassName
                  : unSelectedButtonClassName
              }
              onClick={() => {
                setActiveButton(1);
              }}
            >
              <img
                src="/sale-icon.png"
                alt="Sale Icon"
                height="40"
                width="40"
                className="overflow-visible absolute -left-4 -top-4"
              />
              One Lesson (25% off)
            </button>

            <button
              type="button"
              className={
                numOfLessons === 10
                  ? selectedButtonClassName
                  : unSelectedButtonClassName
              }
              onClick={() => {
                setActiveButton(10);
              }}
            >
              <img
                src="/sale-icon.png"
                alt="Sale Icon"
                height="40"
                width="40"
                className="overflow-visible absolute -left-4 -top-4"
              />
              Ten Lessons (33% off)
            </button>
          </div>
        </div>
        <PricingRow
          products={products}
          numOfLessons={numOfLessons}
          ctaText="Schedule Your Lesson"
          setShowModal={setShowModal}
          setFormFields={setFormFields}
          formFields={formFields}
        />
      </div>
      {leadFormFields && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          type="inform"
          header={leadFormFields.modal ? leadFormFields.modal.text : null}
        >
          <Form
            fields={formFields}
            successMessage={leadFormFields.successMessage}
            onSubmit={(values) => {
              triggerContactFormSubmitted(
                values.Language,
                values["What is your current language ability?"]
              );
              return submitContactForm({ variables: { body: values } });
            }}
          />
        </Modal>
      )}
    </div>
  );
}
