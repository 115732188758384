import React from "react";

import { CheckIcon } from "@heroicons/react/solid";

export default function PricingItem({
  product,
  numOfLessons,
  ctaText,
  setShowModal,
  setFormFields,
  formFields,
}) {
  return (
    <div className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
      <div className="p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {product.displayName}
        </h3>
        {product.description && (
          <p className="mt-4 text-sm text-gray-500">{product.description}</p>
        )}
        <div className="mt-8">
          <span className="text-4xl font-extrabold text-gray-900">
            <div className="relative">
              <span>
                <span className="mt-2 mr-2 text-4xl font-medium">$</span>
                <span className="line-through text-red-600 font-bold">
                  {numOfLessons === 1
                    ? product.lessonPrice
                    : product.bulkLessonsPrice}
                </span>
                <span className="pl-3 font-extrabold ">
                  {" "}
                  {numOfLessons === 1
                    ? product.lessonSalePrice
                    : product.bulkLessonSalePrice}
                </span>
              </span>
              <span className="text-xl font-medium text-gray-500">
                {" "}
                /{" "}
                {numOfLessons === 1
                  ? "lesson"
                  : `${product.bulkLessonAmount} lessons`}
              </span>
            </div>
          </span>
        </div>
        <button
          type="button"
          onClick={() => {
            const updatedFields = [
              ...formFields.filter((field) => field.name !== "Number"),
              {
                name: "Number",
                required: true,
                initialValue: numOfLessons,
                type: "hidden",
              },
            ];
            setFormFields([
              ...updatedFields.filter((field) => field.name !== "Product"),
              {
                name: "Product",
                required: true,
                initialValue: product.name,
                type: "hidden",
              },
            ]);
            setShowModal(true);
          }}
          className="mt-8 block w-full bg-indigo-600 hover:bg-indigo-700 border rounded-md py-2 text-sm font-semibold text-white text-center sign_up_begin"
        >
          {ctaText}
        </button>
      </div>
      <div className="pt-6 pb-8 px-6">
        <p className="text-xs font-medium text-gray-900 tracking-wide uppercase">
          What&apos;s included
        </p>
        <ul className="mt-6 space-y-4">
          {product.features.map((feature) => (
            <li key={feature} className="flex space-x-3">
              <CheckIcon
                className="flex-shrink-0 h-5 w-5 text-green-500"
                aria-hidden="true"
              />
              <span className="text-sm text-gray-500">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
