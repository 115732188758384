import * as React from "react";
import PricingItem from "./PricingItem";

export default function PricingRow({
  products,
  numOfLessons,
  ctaText,
  setShowModal,
  setFormFields,
  formFields,
}) {
  return (
    <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
      {products.map((product) => (
        <PricingItem
          product={product}
          key={product.name}
          numOfLessons={numOfLessons}
          ctaText={ctaText}
          setShowModal={setShowModal}
          setFormFields={setFormFields}
          formFields={formFields}
        />
      ))}
    </div>
  );
}
